<template>
  <b-flix-menu variant="bottom" style="z-index: 100000">
    <ul>
      <li class="brand"><b-flix-logo variant="white" /></li>
      <parts :items="items.appointments.items" :name="items.appointments.name" :icon="items.appointments.icon" class="left" />
      <parts :items="items.calendar.items" :name="items.calendar.name" :icon="items.calendar.icon" />
      <parts :items="items.settings.items" :name="items.settings.name" :icon="items.settings.icon" />
      <parts :items="items.share.items" :name="items.share.name" :icon="items.share.icon" class="right" />
      <parts :items="items.profile.items" :name="items.profile.name" :icon="items.profile.icon" class="right" />
      <li class="onlyXS">
        <router-link :to="{name: 'dashboardLogout'}" class="flix-html-a">
          <flixIcon id="log-out" /> {{ $t("message.logout") }}
        </router-link>
      </li>
      <li class="onlyXS flix-pull-right">
        <switchLanguage />
      </li>
    </ul>
  </b-flix-menu>
</template>
<script>
export default {
  components: {
    parts () { return import('./menu/parts.vue') },
    switchLanguage () { return import('@/components/frontend/navigation/switchLanguage') }
  },
  props: {
    items: {
      type: Object,
      default () { return {} },
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
  }
}
</script>
<style lang="sass">
  .nav-container>ul li.brand
    @media (max-width: 1200px)
      flex: 0 0 auto !important
  .onlyXS
    @media(min-width: 770px)
      display: none !important
</style>
